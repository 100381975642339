import React from 'react';
import './AboutUs.scss';
import { Element } from 'react-scroll';

const AboutUs = () => {
  const content = [
    {
      icon: 'fas fa-crosshairs',
      content:
        'A Rioquimica é uma empresa com mais de três décadas de experiência, o que lhe confere capacidade para aliar credibilidade e inovação nos métodos e moldes de conceção que utiliza.',
    },
    {
      icon: 'fas fa-puzzle-piece',
      content:
        'A base da nossa atividade é sustentada na investigação, desenvolvimento, elaboração e comercialização de produtos químicos, procurando sempre responder às necessidades do mercado e, em particular, dos nossos clientes.',
    },
    {
      icon: 'fas fa-file',
      icon2: 'fas fa-check',
      content:
        'As nossas linhas de produtos apresentam a qualidade e flexibilidade de utilização necessárias que se adequam a diversas industrias e setores. Orgulhamo-nos ainda de ter um portfolio de produtos certificado por laboratórios oficiais, credenciados internacionalmente.',
    },
    {
      icon: 'fas fa-users',
      content:
        'Temos uma equipa de comerciais técnicos especializados, que atuam em Portugal continental, Açores e Madeira, proporcionando assistência na identificação da solução mais adequada a cada cliente, bem como eventual suporte na aplicação e acompanhamento dos resultados obtidos.',
    },
    {
      icon: 'fas fa-truck',
      content:
        'A nossa atividade é ainda complementada por uma rede de logística que assegura o acondicionamento e entrega dos nossos produtos em tempo útil.',
    },
  ];

  return (
    <Element name='us'>
      <div className='WhereHero'>
        <section className='hero'>
          <div className='hero-body'>
            <div className='container'>
              <div className='columns'>
                <div className='column is-narrow '>
                  <h1 className='title'>Sobre nós</h1>
                </div>
              </div>
              <div className='columns'>
                <div className='column List'>
                  {content.map((item, idx) => (
                    <div key={idx} className='columns'>
                      <div className='LeftIcon column is-narrow has-text-centered-mobile'>
                        <div
                          className={`line ${
                            idx === 0
                              ? 'first'
                              : idx === content.length - 1
                              ? 'last'
                              : ''
                          }`}
                        />
                        <span className='icon is-large'>
                          <i className={item.icon + ' fa-lg'} />
                        </span>
                      </div>
                      <div className='column'>
                        <div className='content has-text-justified'>
                          <p>{item.content}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div className='Portugal column is-narrow has-text-centered'>
                  <img src='/images/Portugal.png' alt='Onde atuamos' />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className='section GreenEnergy'>
          <div className='container'>
            <div className='columns is-centered'>
              <div className='LeftIcon column is-narrow is-vertical-center-desktop has-text-centered'>
                <span className='icon is-large'>
                  <i className={'fas fa-leaf fa-3x'} />
                </span>
              </div>
              <div className='column is-two-thirds-desktop'>
                <div className='content has-text-justified'>
                  <p>
                    A nossa preocupação energética e ambiental dinamiza-nos a
                    ter como principal fonte de energia necessária à produção os
                    calores de reação obtidos durante as neutralizações,
                    saponificações e dissoluções. Estimamos que no médio prazo
                    possamos obter a energia necessária para os motores e
                    embalamento a partir de energia solar. Recorrendo a
                    Lavoisier “em que na natureza nada se perde, tudo de
                    transforma”.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Element>
  );
};

export default AboutUs;
