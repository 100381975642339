import * as React from 'react';
import { Element } from 'react-scroll';
import './Hero.scss';

const Hero = () => {
  return (
    <Element name='home'>
      <div className='MainHero'>
        <div id='angles'>
          <div className='flasks' />
          <span />
          <span />
          <span />
        </div>
        <section className='hero '>
          <div className='hero-body'>
            <div className='container'>
              <div className='columns is-centered'>
                <div className='column is-narrow-tablet is-narrow has-text-centered-mobile'>
                  <figure className='Logo image'>
                    <img src='/images/Rioquimica.png' alt='' />
                  </figure>
                </div>
                <div className='column is-two-thirds has-text-centered-mobile'>
                  <h1 className='title'>Rioquimica</h1>
                  <h2 className='subtitle'>
                    Produtos Químicos de Manutenção Industrial, Lda.
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Element>
  );
};

export default Hero;
